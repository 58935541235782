import logger from '../utils/logger';

const isClient = typeof window === 'object';

const getStorageKey = (storage: Storage, key: string): string | null => {
  if (!storage) return null;

  try {
    return storage.getItem(key);
  } catch (error: unknown) {
    if (error instanceof Error) {
      logger.error(`Failed to get storage key "${key}": ${error.message}`);
    }
    return null;
  }
};

const setStorageKey = (storage: Storage, key: string, value: string): boolean => {
  if (!storage) return false;

  try {
    storage.setItem(key, value);
    return true;
  } catch (error: unknown) {
    if (error instanceof Error) {
      logger.error(`Failed to set storage key "${key}": ${error.message}`);
    }
    return false;
  }
};

export const getSessionStorageKey = (key: string): string | null => {
  if (!key || key === '' || !isClient) return null;
  return getStorageKey(window.sessionStorage, key);
};

export const setSessionStorageKey = (key: string, value: string) => {
  if (!key || key === '' || !value || value === '' || !isClient) {
    logger.error('Storage need a key and a value');
    return;
  }
  setStorageKey(window.sessionStorage, key, value);
};
